<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">授权详情</div>
      <div class="nav_left_btn" @click="goBack">
        <img :src="cancel" alt="" width="15" height="25">
      </div>
    </div>
    <div class="content">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="form.name"
            type="form"
            name="name"
            label="姓名"
          />

          <van-field
            v-model="form.phone"
            type="form"
            name="phone"
            label="电话"
          />

          <van-field
            v-model="form.cardno"
            type="form"
            name="cardno"
            label="卡号"
          />

          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit" style="background-color: #ff7b7b;border-color:#ff7b7b">
              提交
            </van-button>
          </div>
        </van-cell-group>
      </van-form>
    </div>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png'
import { Toast } from 'vant'
import { addSmsAuth } from '@/api/notice'
export default {
  name: 'AskNotice',
  data() {
    return {
      cancel,
      // showPicker: false,
      form: {
        name: '',
        phone: '',
        cardno: ''
      }
    }
  },
  created() {

  },
  methods: {
    goBack() {
      this.$router.back(-1)
    },
    async onSubmit(data) {
      console.log('form:')
      console.log(this.form)
      const { form } = this
      const params = {
        name: form.name.trim(),
        cardno: form.cardno.replace(/\s/g, ''),
        phone: form.phone.trim()
      }
      console.log(params)
      const { code, message } = await addSmsAuth(params)
      if (code === 0) {
        Toast.success('授权成功！')
        console.log(data)
        this.$router.push({ name: 'SmsAuth' })
      } else {
        console.log(message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;
  }
  .nav_title {
    display: block;
    font-size: 1.1rem;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    line-height: 49px;
    text-overflow: ellipsis;
    color: #607483;
    width: 60%;
    margin: 0 auto;
    background-color: #ffffff;
  }
  & > .back {
    font-size: 1.1rem;
    margin-left: 3px;
  }
}
.nav_left_btn {
  float: left;
  position: absolute;
  border-width: 9px 5px 9px 7px;
  border-color: transparent;
  background-size: 12px 1.25rem;
  line-height: 1rem;
  font-size: 1rem;
  color: #607483;
  padding: 0 4px 0 15px;
  top: 9px;
  left: 0;
  min-height: 30px;
  display: flex;
  align-items: center;
  & > .nav_right_btn_click {
    opacity: 0.5;
  }
}
.content {
  padding: 4rem 0rem;
}
::v-deep .van-picker__confirm, ::v-deep .van-picker__cancel, ::v-deep .van-field__label, ::v-deep .van-field__control {
  font-size: 16px;
}
::v-deep input.van-field__control {
  background-color: #ffffff;
}
</style>

